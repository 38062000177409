import "./style.css"
import AppWidthWithContainer from "components/AppWidthContainer/AppWidthWithContainer"
import Title from "components/Title/Title"
import { InformationBox } from "components/InformationBox/InformationBox"
import Chest from "./assets/chest.png"
import TonIcon from "./assets/ton-icon.png"
import formatAddress from "helpers/formatAddress"
import { unixToDateString } from "helpers/unixToDateString"
import { getPageData } from "helpers/getPageData"
import { useGlobalStore } from "stores/global"
import { NoData } from "components/NoData/NoData"
import Link from "./assets/link.png"
import WebApp from "@twa-dev/sdk"
import { useEffect,useState } from "react"
import Loader from "components/icons/Loader"

export const Gifts = () => {
    const [loading,setLoading] = useState(false);
    const pageData = getPageData('treasures_page');
    const language = useGlobalStore(state => state.user_info.language);
    const giftsHistory = useGlobalStore(state => state.gifts_history);
    const {setNotification,setGiftsHistory} = useGlobalStore();

    const api = useGlobalStore(state => state.api);

    const textRu = `В игре каждый день у вас есть шанс получить сундук с TON.

Для повышения шансов:

1) Фармите больше монет
2) Чаще посещайте бар
3) Приглашайте друзей

Шанс обнуляется каждый день, не упустите его!`

    const textEn = `In the game, every day you have a chance to get a chest with TON rewards.

To increase the odds:

1) Farm more coins
2) Visit the bar more often
3) Invite your friends

The chance is reset every day, don't miss it!`

useEffect(() => {
    const fn = async ()=>{
        if(giftsHistory) {
            return
        }

        setLoading(true)
        const history = await api.getGiftsHistory()

        if(!history) {
            const notification = {
                show: true,
                points: '',
                username: '',
                message: 'Failed to fetch gifts history. Please try again later'
            }
            setNotification(notification)
            return
        }

        setGiftsHistory(history)
        setLoading(false)

    }

    fn()
},[])

    return <AppWidthWithContainer className = "gifts">
            <div className = "gifts__content">
            <Title size = "medium" text = {pageData.title[language]} />
            <InformationBox title = {pageData.description.title[language]} text = {language == "ru" ? textRu : textEn}  image = {Chest} imageWidth = {"100px"}/>
            <Title text = {pageData.history[language]} size = "small"/>
            <div className = "gifts__history">

                {
                   !giftsHistory ? 
                   <div className = "gifts__history-loader">
                   <Loader fill = "#582B02" width = {40} height = {40}/>
                   </div>
                   : giftsHistory.length == 0 && <NoData title = {pageData.noHistory.title[language]} text = {pageData.noHistory.text[language]}/>
                }

                {
                    giftsHistory &&giftsHistory.sort((a, b) => b.created_at - a.created_at).map((item, index) => {
                        return <div className = "gifts__history-item" key = {index}>
                        <img src = {TonIcon} alt = ""/>
                        <span className = "gifts__history-item-title">{item.amount + " TON"}</span>
                        <div className = "gifts__history-item-text">
                            <span>{unixToDateString(item.created_at)}</span>
                            <span className = "gifts__history-item-hash" onClick = {()=> {WebApp.openLink(`https://tonscan.org/tx/${item.transaction_hash}`,{try_instant_view:true})}}>
                                {formatAddress(item.transaction_hash, 6)}
                                <img src = {Link} alt = ""/>
                            </span>
                        </div>
                    </div>
                    })
                }
            </div>
            </div>

    </AppWidthWithContainer>
}