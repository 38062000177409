import React from "react";
import "./style.css";
import AppWidthWithContainer from "../AppWidthContainer/AppWidthWithContainer";

import LoadingImg from "./assets/loading-img.png";
import { LoadingBar } from "components/LoadingBar/LoadingBar";
import { getPageData } from "helpers/getPageData";
import { useEffect,useState } from "react";

const Loading = ({language,loaderPerc}) => {
  const [text, setText] = useState('');
  const [percent, setPercent] = useState(0);
  const [imageLoaded, setImageLoaded] = useState(false);

  const pageData = getPageData('loading_page');

  const handleImageLoad = () => {
    setImageLoaded(true);
  };
  
  useEffect(() => {
      const stages = pageData.stages;

      const shuffledArray = stages.sort(() => Math.random() - 0.5);

      let currentIndex = 0;
      
      setText(shuffledArray[0].text[language])

      const interval = setInterval(() => {
        const currentStage = shuffledArray[currentIndex];

        setText(currentStage.text[language])
        currentIndex = (currentIndex + 1) % stages.length;

      }, 3000);  

      return () => clearInterval(interval);
  },[])

  useEffect(() => {

    if(loaderPerc == 100) {
      return
    }

    let _percent = 0;
    const startTime = Date.now();
    const interval = setInterval(() => {
      const currentTime = Date.now();
      const elapsedTime = currentTime - startTime;
      const targetTime = 15000; // 15 seconds
      _percent = Math.min(Math.floor((elapsedTime / targetTime) * 100), 100);
      setPercent(_percent);
      if (elapsedTime >= targetTime) {
        clearInterval(interval);
        console.log('Loaded!');
      }
}, 100); // update every 100 milliseconds

    return () => clearInterval(interval);
  }, []);

  return (
    <AppWidthWithContainer className="loading-bg">
      <div className="loading-content">
        <img className="loading-img" src={LoadingImg} alt="" onLoad={handleImageLoad}/>
        { imageLoaded && <LoadingBar text = {text} width = {loaderPerc == 100 ? 100 : percent}/>}
      </div>
    </AppWidthWithContainer>
  );
};

export default Loading;
